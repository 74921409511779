/** @format */

@font-face {
	/* 사용할 폰트의 이름 */
	font-family: "Apple SD Gothic Neo";
	font-display: fallback;

	/* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
	src: url("./AppleSDGothicNeoR.ttf") format("truetype"),
		url("./AppleSDGothicNeoR.woff") format("woff"),
		url("./AppleSDGothicNeoR.eot?iefix") format("embedded-opentype");

	/* 사용할 폰트의 기본 스타일 */
	font-weight: 400;
	font-style: normal;
}

@font-face {
	/* 사용할 폰트의 이름 */
	font-family: "Apple SD Gothic Neo Bold";
	font-display: fallback;

	/* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
	src: url("./AppleSDGothicNeoB.ttf") format("truetype"),
		url("./AppleSDGothicNeoB.woff") format("woff"),
		url("./AppleSDGothicNeoB.eot?iefix") format("embedded-opentype");

	/* 사용할 폰트의 기본 스타일 */
	font-weight: 800;
	font-style: normal;
}
