/** @format */
.main {
	overflow: hidden;
}
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.main-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #252a35;
	height: 48px;
	.logo {
		padding: 12px 16px;
		display: flex;
		justify-content: flex-start;
		border: 0;
		cursor: pointer;
	}
	.logOut {
		width: 70px;
		height: 32px;
		background: #383d48;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #e6e8eb;
		font-size: 13px;
		margin-right: 16px;
		cursor: pointer;
	}
}
.main-content {
	display: flex;
}
.sideBar {
	width: 200px;
	min-height: calc(100vh - 48px);
	max-height: calc(100% - 48px);
	background-color: #383d48;
	overflow-y: auto;

	.list {
		font-size: 16px;
		font-weight: 700;
		height: 44px;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		&:first-child {
			margin-top: 16px;
		}
		.img {
			background-image: url("../../images/main/document.svg");
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
			margin-left: 16px;
			margin-right: 8px;
		}
		// &:hover,
		// &.activate {
		// 	background: #0bcea5;
		// 	border-radius: 10px;
		// 	color: #fff;
		// 	.img {
		// 		background-image: url("../../images/main/bookmark.svg");
		// 	}
		// }
	}
}

input[type="file"] {
	display: none;
}
.container {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: calc(100vw - 200px);
	height: calc(100vh - 48px);
	background-color: #545861;
	padding-top: 20px;
	padding-left: 20px;
	overflow-y: auto;
	.createFile {
		cursor: pointer;
	}
	&-card {
		width: 200px;
		height: 318px;
		background-color: #383d48;
		border-radius: 10px;
		margin: 20px;
		position: relative;
		// &:hover {
		// 	background-color: rgba(13, 16, 26, 0.6);;
		// }
		&:hover .container-card-shadow {
			opacity: 1;
			cursor: pointer;
		}
		& > div > img {
			width: 200px;
			height: 250px;
			object-fit: cover;
			border-radius: 10px 10px 0 0;
		}
		&-shadow {
			position: absolute;
			width: 200px;
			height: 250px;
			top: 0;
			left: 0;
			background: rgba(13, 16, 26, 0.6);
			border-radius: 10px 10px 0 0;
			opacity: 0;

			.iocnGraup {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				margin-top: 12px;
				div {
					width: 44px;
					height: 44px;
					border-radius: 50%;
					margin: 4px 16px;
				}
				.description {
					background: #0bcea5;
					&:hover {
						background: #0c4944;
					}
					.photo {
						width: 24px;
						height: 24px;
						background: url("../../images/main/photo.svg") no-repeat;
						background-size: contain;
						margin: 0;
						border-radius: 0;
					}
				}
				.delete {
					background: #9ea2a8;
					&:hover {
						background: #383c45;
					}
					.trash {
						width: 22px;
						height: 22px;
						background: url("../../images/main/trash.svg") no-repeat;
						background-size: contain;
						margin: 0;
						border-radius: 0;
					}
				}
			}
		}
		.plus {
			width: 72px;
			height: 72px;
			&::before,
			&::after {
				position: absolute;
				top: 127px;
				left: 100px;
				content: "";
				height: 72px;
				width: 2px;
				background-color: #ffffff;
				cursor: pointer;
			}
			&::before {
				transform: rotate(90deg);
			}
		}
		&-title {
			display: flex;
			align-items: center;
			width: 200px;
			height: 40px;
			font-size: 14px;
			flex-grow: 1;
			font-family: "Apple SD Gothic Neo";
			input {
				width: 184px;
				height: 32px;
				padding-left: 8px;
				border: 0;
				margin: 4px 8px;
				background-color: transparent;
				color: #fff;
				box-sizing: border-box;
				font-weight: 700;
				&::placeholder {
					color: #b3b8c0;
					font-weight: 400;
				}
			}
			input:focus-visible {
				border: 2px solid #3390ee;
				background-color: #252a35;
			}
			span {
				color: #fff;
				font-weight: 700;
				padding-left: 16px;
			}
		}
	}
}
.MovableListTitle {
	font-size: 12px;
	color: #f6f7f8;
	padding: 15px;
	text-align: left;
}
.MovableList {
	cursor: pointer;
	img {
		width: 80px;
	}
	p {
		text-align: center;
		color: #9ea3aa;
		padding: 16px;
		font-size: 13px;
	}
	&:hover {
		img {
			border: 8px solid #b3b8c0;
			border-radius: 5px;
		}
	}
}
.MovableList.select {
	img {
		border: 8px solid #b3b8c0;
		border-radius: 5px;
	}
}

.div-active {
	span {
		width: 50px;
		height: 20px;
		border-radius: 2px;
		color: #ffffff;
		font-size: 11px;
		margin-left: 16px;
		margin-bottom: 8px;
		line-height: 18px;
		display: block;
		text-align: center;
	}
	.icon-active-true {
		background-color: #111111;
	}
	.icon-active-false {
		background-color: #555963;
	}
}
