/** @format */

.loading {
	background-color: #555963;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	path {
		fill: #0bcea5;
		stroke: #0bcea5;
	}
	.dots {
		background-color: #0bcea5;
		animation: dots 5s 0s infinite;
		width: 100%;
		height: 100%;
	}
	.loading-img {
		margin-top: 32px;
		width: 80px;
		height: 80px;
		background: #b3b8c0;
		border-radius: 18px;
		overflow: hidden;
		display: flex;
		align-items: flex-end;
		position: relative;
	}
	.exportImg {
		position: absolute;
		top: 0;
		width: 80px;
		height: 80px;
		background: url("../../images/pages/export.svg") no-repeat;
		background-size: contain;
	}
	&-logo {
		margin-top: 25px;
		width: 180px;
		height: 27px;
		background: url("../../images/main/loadding-logo.svg") no-repeat;
		background-size: contain;
	}
}
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background-color: #ccc;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
}
input[type="checkbox"]:checked + label {
	background-color: #111;
}
select {
	width: 120px;
	height: 40px;
	padding: 0.8em 0.8em;
	border: 1px solid #eee;
	font-family: inherit;
	background: url("../../images/pages/arrow.png") no-repeat 95% 50%;
	box-sizing: border-box;
	border-radius: 5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-ms-expand {
	display: none;
}
[z-popup="my-menu"] {
	font-family: "Apple SD Gothic Neo";
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100vw;
	height: 100vh;
	// width: 372px;
	// height: 498px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.modal-shadow {
	font-family: "Apple SD Gothic Neo";
	position: fixed;
	display: none;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	.modal-file {
		width: 420px;
		min-height: 122px;
		background-color: #fff;
		border-radius: 10px;
		font-size: 14px;
		font-family: "Apple SD Gothic Neo";

		&-title {
			padding: 24px;
			color: #373c47;
			font-weight: bold;
		}
		&-input {
			display: flex;
			align-items: center;
			padding: 6px 24px;
			position: relative;
			margin-top: 6px;
			&-text {
				width: 300px;
				height: 44px;
				background: #f6f6f6;
				border: 1px solid #eeeeee;
				padding-left: 16px;
				color: #9ea2a8;
				border-radius: 5px 0 0 5px;
				cursor: pointer;
				position: absolute;
			}
			&-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				width: 88px;
				height: 48px;
				background: #111111;
				border-radius: 5px;
				color: #ffffff;
				cursor: pointer;
				position: absolute;
				right: 24px;
			}
		}
	}
}
.home-move {
	color: #9ea3aa;
	font-size: 30px;
	// line-height: 40px;
	font-family: Poppins;
	position: absolute;
	top: 84px;
	left: 130px;
	display: flex;
	// align-items: center;
	cursor: pointer;
	.arrow-left {
		width: 16px;
		height: 27px;
		background: url("../../images/pages/arrow-left.svg") no-repeat;
		background-size: contain;
		margin-right: 34px;
	}
}
.modal {
	width: 320px;
	min-height: 190px;
	background-color: transparent;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	overflow: hidden;
	&-background {
		background-color: #fff;
		width: 100%;
		height: 134px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&-title {
		font-weight: bold;
		font-size: 20px;
		line-height: 26px;
		color: #373c47;
		padding-top: 38px;
		padding-bottom: 16px;
		font-family: "Apple SD Gothic Neo";
	}
	&-content {
		width: 261px;
		font-size: 14px;
		color: #9ea3aa;
		margin-bottom: 36px;
		padding: 0 20px;
		font-family: "Apple SD Gothic Neo";
		text-align: center;
		line-height: 20px;
	}
	&-actions {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 320px;
		div {
			height: 56px;
			text-align: center;
			line-height: 56px;
			cursor: pointer;
			font-family: Apple SD Gothic Neo;
		}
		&-close {
			width: 50%;
			color: #fff;
			background: #737780;
			font-weight: bold;
			border-radius: 0px 0px 0px 10px;
		}
		&-confirm {
			width: 50%;
			color: #ffffff;
			background: #111111;
			font-weight: bold;
			border-radius: 0px 0px 10px 0px;
		}
		&-export {
			width: 100%;
			color: #fff;
			background: #111111;
			border-radius: 0px 0px 10px 10px;
			font-weight: 700;
		}
	}
}
.modal-export-shadow {
	font-family: "Apple SD Gothic Neo";
	position: fixed;
	display: none;
	background-color: #555963;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1000;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.modal-export {
		border-radius: 10px;
		overflow: hidden;
		background-color: #fff;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 320px;
		.modal-img {
			margin-top: 32px;
			width: 48px;
			height: 48px;
			background: #b3b8c0;
			border-radius: 16px;
			overflow: hidden;
			display: flex;
			align-items: flex-end;
			position: relative;
			.dots {
				background-color: #0bcea5;
				animation: dots 10s 0s infinite;
				width: 100%;
				height: 0%;
			}
			.exportImg {
				width: 48px;
				height: 48px;
				background: url("../../images/pages/export.svg") no-repeat;
				background-size: contain;
				position: absolute;
				top: 0;
			}
		}
		.modal-title {
			font-size: 16px;
			color: #555963;
			padding-top: 16px;
			padding-bottom: 36px;
		}
		.modal-content {
			color: #9ea3aa;
			font-size: 14px;
			margin-bottom: 32px;
		}
		.modal-background {
		}
	}
}
@keyframes dots {
	from {
		height: 0%;
	}
	to {
		height: 100%;
	}
}
.modal-save {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 372px;
	height: 430px;
	background-color: #fff;
	border-radius: 10px;
	font-size: 14px;
	font-family: "Apple SD Gothic Neo";
	&-grid {
		display: flex;
		flex-wrap: wrap;
		&-name {
			min-width: 100%;
			height: auto;
			padding: 32px 0 8px 20px;
			font-weight: bold;
			color: #545861;
		}
		&-input {
			width: 100%;
			display: flex;
			justify-content: center;
			input {
				width: 100%;
				height: 40px;
				background: #f6f7f8;
				border: 0;
				padding-left: 16px;
				box-sizing: border-box;
				margin: 0 20px;
				box-sizing: border-box;
				border-radius: 5px;
			}
		}
		&-page {
			width: 100%;
			padding-left: 20px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.checkbox-label {
				width: 55px;
			}
			.selectionRange {
				width: 156px;
				height: 40px;
				// background: #f6f6f6;
				border: 1px solid #eeeeee;
				box-sizing: border-box;
				padding-left: 16px;
				color: #9ea2a8;
				border-radius: 5px;
				margin-right: 20px;
			}
		}
		&-half {
			padding: 0 20px;
			&:last-child {
				padding-left: 0;
			}
			.modal-save-grid-name {
				padding-left: 0;
			}
			select {
				//margin-left: 20px;
			}
			.input-save {
				width: 192px;
				height: 40px;
				// background: #f6f6f6;
				border: 1px solid #eeeeee;
				box-sizing: border-box;
				border-radius: 5px;
				color: #9ea2a8;
				padding-left: 16px;
			}
		}
		.modal-save-route {
			display: flex;
			padding: 0 20px;
			&-input {
				width: 232px;
				height: 40px;
				padding-left: 16px;
				background: #f6f6f6;
				border: 1px solid #eeeeee;
				box-sizing: border-box;
				border-radius: 5px 0 0 5px;
				color: #9ea2a8;
			}
			&-input-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 40px;
				background: #545861;
				border-radius: 5px;
				font-weight: bold;
				color: #fff;
				cursor: pointer;
			}
		}
	}
	.total-page {
		display: flex;
		justify-content: flex-end;
		margin-top: 24px;
		margin-bottom: 32px;
		&-title {
			color: #737780;
			margin-right: 10px;
		}
		&-number {
			color: #373c47;
			font-weight: bold;
			margin-right: 22px;
			font-family: Poppins;
		}
	}
	&-actions {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		div {
			height: 56px;
			text-align: center;
			line-height: 56px;
			cursor: pointer;
			font-size: 16px;
			font-weight: bold;
		}
	}
}
